import styled from '@emotion/styled'
import { css } from '@emotion/react'
import tw from 'twin.macro'

export const OrderDetailsContainer = styled('div')`
  ${tw`max-w-sm`}
  input {
    ${tw`w-full`}
  }
`
export const CardAddressContainer = styled('div')`
  input {
    ${tw`w-full`}
  }
`

export const YourPretSignUpContainer = styled('div')`
  input {
    ${tw`w-10/12 max-w-sm`}
  }
`

export const FormWrapper = styled('form')`
  ${tw`w-full outline-none`}

  ${({ id }) => (id === 'contact-us' ? tw`lg:w-3/4 m-auto` : '')}
`

export const Row = styled('div')`
  ${tw`flex flex-wrap md:flex-nowrap mb-8`}
`
export const CardAddressRow = styled('div')`
  ${tw`flex flex-wrap md:flex-nowrap text-sm w-full`}
  span {
    ${tw`text-sm`}
  }
  h3 {
    ${tw`font-bold text-xl`}
    letter-spacing: 0.005em
  }
`

export const RowsGroup = styled('div')`
  ${tw`md:w-full md:flex`}
`

const fWidth = css`
  ${tw`w-full md:w-full mx-0!`}
`

type FieldProps = {
  fullWidth?: boolean
}

export const Field = styled('div')<FieldProps>`
  ${tw`w-full`} :nth-of-type(2) {
    ${tw`mt-8 md:mt-0 md:ml-4`}
  }

  ${({ fullWidth }) => (fullWidth ? fWidth : '')}
`

export const WarningWrapper = styled('div')`
  ${tw`mb-6`}
  ${props =>
    props['data-formid'] === 'contact-us' ? tw`w-full` : tw`md:w-1/2`}
`

export const Hr = styled('hr')`
  ${tw`border-t-2 border-pretRed-700 w-10 mt-4 mb-6`}
`

export const ZeroBagTermsAndConditions = styled('div')`
  ${tw`mb-3`}

  * {
    ${tw`text-sm`}
  }
`

export const ErrorText = styled('p')`
  ${tw`mt-1 tracking-sm text-errorRed`}
`
